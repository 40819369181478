import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Section, Box, Text, Span } from '../../components/Core'
import imgBackground from '../../assets/image/jpeg/orchestra.jpg'

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section
        bg='dark'
        css={`
          background-image: url(${imgBackground});
          background-size: cover;
          background-position: center;
          padding-bottom: 750px;
          min-height: 100vh;
        `}
      >
        <div className='pt-5' />
        <Container
          className='position-relative'
          css={`
            z-index: 10;
          `}
        >
          <Row>
            <Col md='11' lg='10' xl='9'>
              <Box py={4}>
                <Text
                  color='light'
                  data-aos='fade-up'
                  data-aos-duration='1500'
                  data-aos-once='true'
                  data-aos-delay='700'
                  css={`
                    max-width: 90%;
                  `}
                >
                  Ensemble-driven product engineering
                </Text>
                <Title
                  my={4}
                  variant='hero-jazzy'
                  color='light'
                  data-aos='fade-up'
                  data-aos-duration='1500'
                  data-aos-once='true'
                  data-aos-delay='1000'
                >
                  Let's make something <Span color='primary'>beautiful</Span>{' '}
                  together.
                </Title>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Hero
