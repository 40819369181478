import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Button, Box, Text, Input } from '../../components/Core'

import { device } from '../../utils'

import imgShape from '../../assets/image/svg/contact-shape.svg'

const BoxStyled = styled(Box)`
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 49%;
    content: '';
    width: 100%;
    background: ${({ theme }) => theme.colors.ash};
    z-index: 1;
  }
`

const Shape = styled(Box)`
  position: absolute;
  width: 150%;
  bottom: 48%;
  left: -5%;

  @media ${device.lg} {
    bottom: 47%;
  }
`

const FormStyled = styled.form`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 1px;
  background-color: #c2c2c2;
  width: 100%;
`

const Contact = () => (
  <>
    <BoxStyled
      bg='dark'
      pt={[0, null, null, 4]}
      pb={6}
      className='position-relative'
    >
      <Shape>
        <img src={imgShape} alt='' className='w-100 img-fluid' />
      </Shape>
      <Container
        css={`
          position: relative;
          z-index: 10;
        `}
      >
        <Row className='justify-content-center'>
          <Col
            lg='9'
            xl='8'
            data-aos='fade-up'
            data-aos-duration='750'
            data-aos-once='true'
            data-aos-delay='500'
          >
            <FormStyled
              data-netlify='true'
              name='contact-form'
              action='/thank-you'
            >
              <input type='hidden' name='form-name' value='contact-form' />

              <div className='mb-5 text-center'>
                <Title className='mb-2'>Bring your vision to life.</Title>
                <Text>Get in touch to scope out your next project.</Text>
              </div>

              <Box mb={3}>
                <Input
                  name='email'
                  type='email'
                  placeholder='Email address'
                  required
                />
              </Box>
              <Box mb={4} className='position-relative'>
                <Input
                  as='textarea'
                  name='description'
                  placeholder='What does success look like for your project?'
                  height='200px'
                  required
                />
              </Box>

              <Button width='100%' type='submit' borderRadius={1} bg='ash'>
                Send
              </Button>
            </FormStyled>
          </Col>
        </Row>
      </Container>
    </BoxStyled>
  </>
)

export default Contact
