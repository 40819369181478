import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Section, Box, Text } from '../../components/Core'
import { device } from '../../utils'

import featuresData from './features-data'

const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`

const TitleStyled = styled(Title)`
  color: ${({ theme }) => theme.colors.lightShade};
`

const TextStyled = styled(Text)`
  color: ${({ theme }) => theme.colors.lightShade};
`

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <img src={iconImage} alt='' />
    </Box>
    <Box>
      <TitleStyled variant='card' fontSize='24px' mb={3}>
        {title}
      </TitleStyled>
      <TextStyled variant='small'>{children}</TextStyled>
    </Box>
  </Box>
)

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled bg='dark'>
      <Container>
        <Row className='justify-content-center mb-5'>
          <Col lg='10'>
            <div className='text-center'>
              <Title color='light'>
                Full-spectrum technical services to help you thrive in the
                digital economy
              </Title>
            </div>
          </Col>
        </Row>

        {featuresData.map((featureEntries, row) => (
          <Row key={row} className='justify-content-center'>
            {featureEntries.map((featureEntry, col) => (
              <Col
                key={col}
                lg='4'
                className='mb-5 mb-lg-4'
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-once='true'
                data-aos-delay={400 * row + 200 * col}
              >
                <FeatureCard
                  title={featureEntry.title}
                  iconImage={featureEntry.icon}
                >
                  {featureEntry.description}
                </FeatureCard>
              </Col>
            ))}
          </Row>
        ))}
      </Container>
    </SectionStyled>
  </>
)

export default Feature
