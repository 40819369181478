import React from "react";

import Contact from "../sections/landing-page/Contact";
import Feature from "../sections/landing-page/Feature";
import Hero from "../sections/landing-page/Hero";
import PageWrapper from "../components/PageWrapper";

import SEO from "../components/SEO";

const IndexPage = () => {
  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title="Home" />
      {/*  headerDark  headerCTA  */}
      <PageWrapper footerDark hideFooterLogo={true}>
        {/* <Hero bg='dark' /> */}
        {/* <Feature bg='dark' /> */}
        {/* <div id='contact' /> */}
        {/* <Contact bg='dark' /> */}
      </PageWrapper>
    </>
  );
};
export default IndexPage;
